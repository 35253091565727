<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row class="mb-3">
        <b-col cols="3">
          <div style="overflow-y: scroll; height:88vh;">
            <b-list-group>
              <b-list-group-item
                :to="{ name: 'TemplatePreview', query: { template: template } }"
                v-for="(template, index) in templateList"
                :key="index"
                :variant="templateVariantStyle(template)"
              >
                <span>{{ template }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
        <b-col cols="9">
          <b-row v-if="templateType !== 'document'" class="mb-3">
            <b-col>
              <b-button-group class="mr-4">
                <b-button variant="primary" @click="setTemplateHtml(true)">
                  <font-awesome-icon icon="sync" />
                </b-button>
              </b-button-group>
            </b-col>
            <b-col class="text-right">
              <b-button-group class="mr-4">
                <b-button
                  @click="setPreview('html')"
                  :variant="preview === 'html' ? null : 'primary'"
                  >HTML
                </b-button>
                <b-button
                  @click="setPreview('desktop')"
                  :variant="preview === 'desktop' ? null : 'primary'"
                  >Desktop
                </b-button>
                <b-button
                  @click="setPreview('mobile')"
                  :variant="preview === 'mobile' ? null : 'primary'"
                  >Mobile
                </b-button>
              </b-button-group>
              <b-dropdown id="dropdown-right" right variant="primary">
                <template #button-content>
                  <font-awesome-icon icon="envelope" />
                  Test
                </template>
                <b-dropdown-form inline style="width: 428px;" class="mb-2">
                  <b-form-group
                    label="Send test email to"
                    label-for="dropdown-form-email"
                  >
                    <b-form-input
                      :placeholder="$store.getters.jwtPayload.email"
                      disabled
                      style="width: 300px;"
                      class="mr-3"
                    ></b-form-input>
                    <b-button variant="primary" @click="sendTestEmail"
                      >Send
                    </b-button>
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="d-flex justify-content-center">
              <div
                :style="
                  'width: ' + previewWidth + '; height: ' + previewHeight + ';'
                "
              >
                <span v-if="!!templateHtml">
                  <iframe
                    v-if="templateType === 'document'"
                    :src="
                      'data:application/pdf;base64, ' + encodeURI(templateHtml)
                    "
                    style="width: 100%; height: 100%; border: 1px solid #ced4da; border-radius: .25rem;"
                  />
                  <span v-else>
                    <b-form-input v-model="subject" class="mb-3" disabled />
                    <b-form-input
                      v-model="titlePreview"
                      class="mb-3"
                      disabled
                    />
                    <pre
                      v-if="preview === 'html'"
                      style="height: 100%; border: 1px solid #ced4da; border-radius: .25rem;"
                      >{{ templateHtml }}</pre
                    >
                    <iframe
                      v-if="preview === 'desktop' || preview === 'mobile'"
                      :srcdoc="templateHtml"
                      style="width: 100%; height: 100%; border: 1px solid #ced4da; border-radius: .25rem;"
                    />
                  </span>
                </span>
                <div
                  v-if="!templateHtml && templateSelected"
                  style="text-align: center;"
                >
                  <b-spinner
                    style="position: fixed; top: 50%;"
                    variant="primary"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "TemplatePreview",
  data() {
    return {
      templateHtml: null,
      preview: "desktop",
      templateSelected: this.$route.query.template,
      templateList: [],
      subject: null,
      titlePreview: null
    };
  },
  beforeMount() {
    this.setTemplateList();
  },
  mounted() {
    console.log(this.templateSelected);
    if (this.templateSelected) this.setTemplateHtml();
  },
  computed: {
    previewWidth() {
      if (this.templateType === "document") return "100%";
      let result = "";
      switch (this.preview) {
        case "html":
          result = "100%";
          break;
        case "desktop":
          result = "700px";
          break;
        case "mobile":
          result = "320px";
          break;
      }
      return result;
    },
    previewHeight() {
      if (this.templateType === "document") return "88vh";
      return "71vh";
    },
    templateType() {
      if (this.templateSelected === undefined) return null;
      return this.templateSelected.split("/")[0];
    }
  },
  watch: {
    "$route.query.template"(value) {
      this.templateSelected = value;
      this.setTemplateHtml();
    },
    templateType() {
      this.templateHtml = "";
    }
  },
  methods: {
    setTemplateHtml(forceUpdate = false) {
      let serviceName = "code";
      if (forceUpdate) serviceName = "code-cache-update";
      if (this.templateSelected.search("document") > -1)
        serviceName = "document-code";
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}template/${serviceName}?name=${this.templateSelected}`
        )
        .then(res => {
          if (serviceName === "document-code")
            this.templateHtml = res.data.html;
          else this.templateHtml = res.data.html.replaceAll("><", ">\r\n<");
          this.titlePreview = res.data.titlePreview;
          this.subject = res.data.subject;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setTemplateList() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}template/list`)
        .then(res => {
          this.templateList = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    sendTestEmail() {
      this.$http
        .post(`${process.env.VUE_APP_API_ENDPOINT}template/test`, {
          name: this.templateSelected
        })
        .then(res => {
          this.registered = res.data.success;
        })
        .catch(err => {
          this.error = err;
        });
    },
    setPreview(type) {
      this.preview = type;
    },
    templateVariantStyle(template) {
      let result = "";
      switch (template.split("/")[0]) {
        case "document":
          result = "secondary";
          break;
        case "email":
          result = "warning";
          break;
        case "newsletter":
          result = "primary";
          break;
      }
      return result;
    }
  }
};
</script>

<style>
pre {
  background: rgb(33, 33, 33);
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #d9d9d9;
  page-break-inside: avoid;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
</style>
